$ = jQuery;

$(document).ready(function ($) {
	"use strict";

	$('.hamburger').click(function () {
		$(this).toggleClass('active');
		$('.navigation').toggleClass('active');
		$('body').toggleClass('disable-scroll-mob');
	});

	$('.hero__slider').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		dots: true,
		autoplay: true,
		autoplaySpeed: 3000,
	});

	$('.brands__slider').slick({
		infinite: true,
		slidesToShow: 6,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		autoplay: true,
		autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2
				}
			}
		]
	});

	// $('.header__search-icon-mob').click(function () {
	// 	$('.header__search').addClass('active');
	// });

	$('.category-nav__header').click(function () {
		$('.category-nav nav').slideToggle(300);
	});

	$('.header__search-icon-mob').click(function () {
		$(this).toggleClass('active');
		$('.header__search').slideToggle(300);
	});

	$('.value select, .orderby').niceSelect();

	$('.achive-content__more').click(function () {
		$(this).toggleClass('active');
		$('.achive-content__text').toggleClass('active');
	});

	$('.faq__item').click(function () {
		$(this).toggleClass('active').find('.faq__item-text').slideToggle(300);
	});

	$('.shop-filter-header').click(function () {
		$('.shop-filter').addClass('active');
		$('body').toggleClass('disable-scroll-mob');
	});

	$('.shop-filter-header').click(function () {
		$('.shop-filter').addClass('active');
		$('body').addClass('disable-scroll-mob');
	});

	$('.shop-filter__close').click(function () {
		$('.shop-filter').removeClass('active');
		$('body').removeClass('disable-scroll-mob');
	});

	$('.instagram__slider').slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.distributor__slider').slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('ul.tabs li').click(function(){
		var tab_id = $(this).attr('data-tab');

		$('ul.tabs li').removeClass('current');
		$('.tab-content').removeClass('current');

		$(this).addClass('current');
		$("#"+tab_id).addClass('current');
		$('.distributor__slider').slick('refresh');
	});


	$('.distributor__item-btn').click(function () {
		$(this).toggleClass('active').parent().find('.distributor__item-addresses').toggleClass('active');
	});

	$(window).on('load resize', function () {
		if( $(window).width() <= 767) {
			$('.woocommerce-cart .product-subtotal').removeAttr('style');
		}
	});



});

